var config = {
	title: window.location.hostname==='hennessy.co.de'?'hennessy.co.de':'szar.dev',
	description: 'Full Stack Developer',
	author: window.location.hostname==='hennessy.co.de'?'Alyssa Hennessy':'Szar',
	contact: {
		linkedin: window.location.hostname==='hennessy.co.de'?'https://www.linkedin.com/in/alyssa-hennessy/':null,
		github: 'https://github.com/Szar',
		email:  window.location.hostname==='hennessy.co.de'?'mailto:aly.hennessy@gmail.com':'mailto:me@szar.dev'
	},
	skills: [
		{
			name:'Languages',
			data:[
				{'name':'Python','level':1},
				{'name':'PHP','level':1},
				{'name':'JavaScript','level':1},
				{'name':'CSS/Sass','level':2},
				{'name':'HTML','level':2},
				{'name':'SQL','level':3},
				//{'name':'Shell','level':3}
			]
		},
		{
			name:'Frameworks',
			data:[
				{'name':'React','level':1},
				{'name':'Flask','level':1},
				{'name':'Node.js','level':1},
				{'name':'Codeigniter','level':1},
				{'name':'D3.js','level':2},
				{'name':'Django','level':2},
				//{'name':'Laravel','level':3}
			]
		},
		{
			name:'Other',
			data:[
				{'name':'MongoDB','level':1},
				{'name':'MySQL','level':1},
				//{'name':'LAMP Stack','level':1},
				{'name':'AWS EC2','level':2},
				{'name':'Git','level':2},
				{'name':'Wordpress','level':2},
				{'name':'Socket.io','level':3},
			]
		}

	],
	projects: [

		{
			type: 'link',
			url: 'https://embedtest.com/',
			thumb: '/thumb/embedtest.png',
			client: 'EmbedTest.com',
			title: 'Tool for quick embed code testing',
			//date: '2019',
			tags: ['React']
		},
		{
			type: 'link',
			url: 'https://tabl.dev/',
			thumb: '/thumb/tabl.png',
			client: 'Tabl.dev',
			title: 'Tool to create quick tables on the fly',
			//date: '2019',
			tags: ['React']
		},
		{
			type: 'link',
			url: 'https://ec2-52-36-103-254.frac.tl/clients/better-buys/password-crack-times/',
			thumb: '/thumb/password-crack-times.png',
			client: 'Better Buys',
			title: 'Password Crack Times',
			//date: '2016',
			tags: ['React']
		},
		{
			type: 'link',
			url: 'https://szar.dev/slidebuilder/',
			thumb: '/thumb/sliderbuilder.png',
			client: 'SliderBuilder',
			title: 'Drag and drop tool to create sliders',
			//date: '2020',
			tags: ['React', 'Dropzone.js']
		},
		
		{
			type: 'link',
			url: 'https://ec2-52-36-103-254.frac.tl/clients/vertical-scope/burning-off-the-night-before/',
			thumb: '/thumb/burning-off-alt.png',
			client: 'VerticalScope',
			title: 'Burning Off Last Night\'s Drinks',
			//date: '2019',
			tags: ['React', 'Anime.js']
		},
		
		{
			type: 'link',
			url: 'https://ec2-52-36-103-254.frac.tl/clients/directv/legend-of-the-whitlock-estate/',
			thumb: '/thumb/whitlock-estate.png',
			client: 'DIRECTV',
			title: 'Legend of the Whitlock Estate',
			date: '2019',
			tags: ['React']
		},
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/superdrug/ed-calculator/',
			thumb: '/thumb/superdrug_ed.jpg',
			client: 'Superdrug',
			title: 'Erectile Dysfunction Risk Calculator',
			date: '2015',
			// tags: ['D3.js']
		},
		{
			type: 'link',
			url: 'https://ec2-52-36-103-254.frac.tl/clients/superdrug/sexercise-calculator/',
			thumb: '/thumb/sexercise_alt.png',
			client: 'Superdrug',
			title: 'Sexercise: How Many Calories Do You Burn During Sex?',
			//date: '2015',
			tags: ['React']
		},

		
		
		{
			type: 'link',
			url: 'https://github.com/Szar/RGeo',
			thumb: '/thumb/rgeo.png',
			client: 'RGeo',
			title: 'Reverse geocode points with SHP data',
			date: '2018',
			tags: ['Python', 'Shapely']
		},
		
		
		{
			type: 'link',
			url: 'https://ec2-52-36-103-254.frac.tl/clients/fanatics/passing-touchdowns-interactive/',
			thumb: '/thumb/passing-touchdowns-interactive.png',
			client: 'Fanatics.com',
			title: 'Passing Touchdowns: Mapping Every Touchdown in the NFL',
			//date: '2015',
			tags: ['React', 'Three.js']
		},
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/recoverybrands/nightout/show.php?id=99',
			thumb: '/thumb/rehabs-nightout.jpg',
			client: 'Recovery Brands',
			title: 'Night Out Drinking: Alcohol Calculator',
			date: '2015',
			// tags: ['Chart.js', 'PHP', 'MySQL']
		},
		
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/fanatics/jersey-buying-guide/',
			thumb: '/thumb/jersey-buying-guide.png',
			client: 'Fanatics.com',
			title: '2015 Fanatics NFL Jersey Buying Guide',
			date: '2015',
			// tags: ['jQuery', 'fullPage.js']
		},
		
		
		
		
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/tivo/',
			thumb: '/thumb/tivo_roi.jpg',
			client: 'TiVo',
			title: 'See How You Can Save With Tivo',
			date: '2015',
			// tags: ['jQuery']
		},
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/tickpick/big-dick-energy-quiz/',
			thumb: '/thumb/testicular-cancer-quiz.png',
			client: 'TickPick',
			title: 'Testicular Cancer Knowledge Quiz',
			date: '2019',
			// tags: ['jQuery', 'fullPage.js']
		},
		
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/angieslist/guides/',
			thumb: '/thumb/buyersguide1.jpg',
			client: 'Angies List',
			title: 'Trusted Buyers Guide',
			date: '2016',
			// tags: ['jQuery', 'ScrollMagic', 'Parallax']
		}, {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/fanatics/nba-playoffs-interactive/',
			thumb: '/thumb/nbaplayoffs.jpg',
			client: 'Fanatics',
			title: 'NBA Playoffs Live Ranking',
			date: '2016',
			// tags: ['Node.js']
		},  {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/directv/game-of-thrones-predictions/',
			thumb: '/thumb/got-quiz.jpg',
			client: 'DIRECTV',
			title: 'Game of Thrones: Charting the Path to the Iron Throne',
			date: '2017',
			// tags: ['jQuery', 'D3.js']
		}, {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/selecthub/mapping-saas-integrations/',
			thumb: '/thumb/sass-network.jpg',
			client: 'SelectHub',
			title: 'Mapping SaaS Integrations',
			date: '2017',
			// tags: ['Gephi']
		}, {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/fanatics/wonderlic-test/',
			thumb: '/thumb/wonderlic.jpg',
			client: 'Fanatics',
			title: 'Wonderlic Test',
			date: '2016'
		},
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/directv/tsn/taylor-swift-now-111017/',
			thumb: '/thumb/taylor-swift-moas-2.jpg',
			client: 'DIRECTV',
			title: 'Taylor Swift NOW',
			date: '2017-2018',
			// tags: ['jQuery', 'wow.js']
		},  {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/red-ventures/vampire-energy-interactive/',
			thumb: '/thumb/energy-house.jpg',
			client: 'Red Ventures',
			title: 'Energy Maintenance Checklist',
			date: '2017',
			// tags: ['jQuery', 'SVG']
		}, {
			type: 'link',
			url: 'http://www.projectknow.com/discover/overdosingamerica-embed/',
			thumb: '/thumb/rehabs_overdose.jpg',
			client: 'Recovery Brands',
			title: 'Which Substance Are You Most Likely to Overdose With?',
			date: '2015',
			// tags: ['jQuery']
		}, /*{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/theredpin/americans-moving-to-canada/',
			thumb: '/thumb/canada.jpg',
			client: 'The Red Pin',
			title: 'Moving To Canada Live Feed',
			date: '2016',
			// tags: ['Node.js']
		},*/ {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/red-ventures/climate-map/',
			thumb: '/thumb/climate-map.jpg',
			client: 'Red Ventures',
			title: 'Climate Equivalences',
			date: '2017',
			// tags: ['D3.js', 'Leaflet']
		}, {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/directv/twin-peaks-challenge/',
			thumb: '/thumb/twinpeaks-quiz.jpg',
			client: 'DIRECTV',
			title: 'The Twin Peaks Challenge',
			date: '2018',
			// tags: ['jQuery']
		}, /*{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/income-store/fast-food-workout-plan/',
			thumb: '/thumb/fastfood.jpg',
			client: 'Home Remedy Shop',
			title: 'Your Fast Food Workout Plan',
			date: '2016',
			// tags: ['PHP', 'SQLite']
		}, */{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/quote/2018-credit-card-preferences/preview.php#credit-card-preferences',
			thumb: '/thumb/card-preferences-scores.jpg',
			client: 'Branded Holdings',
			title: '2018 Credit Card Preferences Survey',
			date: '2017',
			// tags: ['D3.js']
		}, /*
		{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/vividseats/going-the-distance/',
			thumb: '/thumb/vivid_outofstate.jpg',
			client: 'Vividseats',
			title: 'Going the Distance: Which Football Fans Travel Farthest?',
			date: '2015',
			// tags: ['D3.js']
		},
		*/ {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/recoverybrands/drug-mentions-interactive/',
			thumb: '/thumb/tvdrug.jpg',
			client: 'Recovery Brands',
			title: 'Drug Mentions on TV',
			date: '2016',
			// tags: ['D3.js']
		},{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/dred/sexual-exposure/#sexual-exposure-calculator',
			thumb: '/thumb/superdrug_exposure.jpg',
			client: 'Superdrug',
			title: 'Sexual Exposure Calculator',
			date: '2015',
			// tags: ['jQuery']
		}, /*{
			type: 'link',
			url: 'https://www.cometfi.com/refi-ready',
			thumb: '/thumb/refi-ready.jpg',
			client: 'Comet',
			title: 'ReFi Ready Calculator',
			date: '2018',
			// tags: ['D3.js', 'Hubspot']
		},*/  {
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/red-ventures/internet-speed-interactive/',
			thumb: '/thumb/internetspeed.jpg',
			client: 'Red Ventures',
			title: 'How Much Internet Speed Do You Need?',
			date: '2016',
		}, /*{
			type: 'link',
			url: 'http://ec2-52-36-103-254.us-west-2.compute.amazonaws.com/clients/canva/average-face-brand-model/',
			thumb: '/thumb/canva-faces.jpg',
			client: 'Canva',
			title: 'The Average Face of a Brand Model',
			date: '2015'
		},*/ {
			type: 'link',
			url: 'http://www.alexa.com/2016-content-calendar-plus-seasonal-marketing-ideas/#interactive',
			thumb: '/thumb/alexa_cal.jpg',
			client: 'Alexa.com',
			title: '2016 Content Calendar Plus Seasonal Content Ideas',
			date: '2015',
			// tags: ['fullCalendar']
		}, {
			type: 'link',
			url: 'https://www.projectknow.com/discover/drug-zones-across-the-us-interactive/',
			thumb: '/thumb/rehabs_drugzones.jpg',
			client: 'Recovery Brands',
			title: 'Visualizing Drug Zones Across U.S.',
			date: '2015'
		}, /*{
			type: 'link',
			url: 'http://www.chateauelan.net/',
			thumb: '/thumb/thumb_chateau.jpg',
			client: 'Chateau Elan Estates',
			title: 'ChateauElan.net Site',
		}, {
			type: 'link',
			url: 'https://www.busbud.com/blog/basketball-ticket-deals-study/',
			thumb: '/thumb/busbud-roi.jpg',
			client: 'BusBud',
			title: 'Which NBA teams deliver the best value for the money to fans?',
			date: '2015'
		},*/ {
			type: 'lightbox',
			url: '/full/ExceleronIDX.jpg',
			thumb: '/thumb/thumb_exceleronidx.jpg',
			client: 'Exceleron Designs',
			title: 'Exceleron Designs IDX Portal',
			// tags: ['CodeIgniter','PHP','MySQL']
		}, {
			type: 'lightbox',
			url: '/full/WCI2.jpg',
			thumb: '/thumb/thumb_wcire.jpg',
			client: 'WCI Communities',
			title: 'WCICommunities.com Site',
			tags: ['MODX']
		}, {
			type: 'lightbox',
			url: '/full/Frameless.jpg',
			thumb: '/thumb/thumb_frameless.jpg',
			client: 'Frameless Shower Doors',
			title: 'FramelessShowerDoors.com Site',
			tags: ['Magento']
		}, /*{
			type: 'lightbox',
			url: '/full/wellington.jpg',
			thumb: '/thumb/thumb_wellington.jpg',
			client: 'Wellington Anti Aging',
			title: 'WellingtonAntiAging.com Site',
			// tags: ['MODX']
		},*/ {
			type: 'link',
			url: 'https://szar.dev/archive/adgirl/',
			thumb: '/thumb/ad-02.jpg',
			client: 'AdGirl',
			title: 'AdGirl, Inc. Site'
		}, {
			type: 'link',
			url: 'http://admiralscove.com/',
			thumb: '/thumb/thumb_admirals.jpg',
			client: 'Admirals Cove',
			title: 'AdmiralsCove.com Site'
		}, /*{
			type: 'lightbox',
			url: '/full/hopesgate.jpg',
			thumb: '/thumb/thumb_hopesgate.jpg',
			client: 'HopesGate',
			title: 'HopesGate.net Site',
			// tags: ['MODX']
		}, {
			type: 'link',
			url: 'http://fractlstaging.com/clients/lexington-law/living-wadge-interactive/',
			thumb: '/thumb/credit_map.jpg',
			client: 'CreditRepair.com',
			title: 'Discover Which County Offers the Best Cost of Living',
			date: '2015',
			// tags: ['D3.js']
		},*/ {
			type: 'lightbox',
			url: '/full/AzureSales.jpg',
			thumb: '/thumb/azure1.png',
			client: 'Azure Properties',
			title: 'Azure Property Management Portal',
			tags: ['CodeIgniter']
		}, {
			type: 'lightbox',
			url: '/full/hewpla.jpg',
			thumb: '/thumb/thumb_hewpla.jpg',
			client: 'HEW',
			title: 'HEWPLA.com Site',
			// tags: ['MODX']
		}, {
			type: 'lightbox',
			url: '/full/frieght.jpg',
			thumb: '/thumb/thumb_frieghtcars.jpg',
			client: 'FreightCars.com',
			title: 'FreightCars.com Site',
			// tags: ['MODX']
		}, {
			type: 'link',
			url: 'https://szar.dev/archive/gripripper/?v=2',
			thumb: '/thumb/gr-01.jpg',
			client: 'GripRipper',
			title: 'GripRipper.com Site',
			date: '2013',
			tags: ['Shopify']
		}, {
			type: 'lightbox',
			url: '/full/js-02.jpg',
			thumb: '/thumb/js-02.jpg',
			client: 'JSFishcamp',
			title: 'JSFishcamp.com Site'
		}, /*{
			type: 'lightbox',
			url: '/full/WCIHR.jpg',
			thumb: '/thumb/wcihr3.png',
			client: 'WCI Communities',
			title: 'WCICommunities.com HR Portal',
			// tags: ['CodeIgniter','PHP','MySQL']
		}*/
	]

};

module.exports = config;